.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: system-ui, -apple-system, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.langButton {
  padding: 8px 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  cursor: pointer;
}

.uploadArea {
  border: 2px dashed #ddd;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  background-color: #fafafa;
  cursor: pointer;
  transition: all 0.2s;
}

.uploadAreaActive {
  border-color: #3e2723;
  background-color: #e3f2fd;
}

.uploadButton {
  background-color: #3e2723;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.image {
  max-width: 100%;
  max-height: 300px;
  margin: 20px auto;
  display: block;
  border-radius: 8px;
}

.result {
  margin-top: 20px;
  padding: 15px;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  font-size: 1.4em; 
  color: #684e33;
}

.error {
  margin-top: 20px;
  padding: 15px;
  border-radius: 4px;
  background-color: #ffebee;
  color: #c62828;
  text-align: center;
}

.progress {
  width: 100%;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 10px;
  visibility: hidden;
}

.progressBar {
  height: 100%;
  background-color: #837a72;
  transition: width 0.3s ease;
}

.loading {
  text-align: center;
  margin-top: 20px;
  color: #666;
}
